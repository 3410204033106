import MainLogo from "../../components/svgs/MainLogo";
import PhoneIcon from "../../components/svgs/PhoneIcon";
import FacebookIcon from "../../components/svgs/FacebookIcon";
import InstagramIcon from "../../components/svgs/InstagramIcon";
import TelegramIcon from "../../components/svgs/TelegramIcon";
import { Form } from "../../components/Form";
import YoutubeIcon from "../../components/svgs/YoutubeIcon";
import "./MainPage.scss";
import { GetData } from "../../FetchData";
import { useState } from "react";

const MainPage = () => {
  const [appealID, setAppealID] = useState(null);
  const [appealStatus, setAppealStatus] = useState(null);
  const handleCheckAppeal = (e) => {
    e.preventDefault();
    if (appealID) {
      GetData(`status/${appealID}`).then((res) => setAppealStatus(res.state));
    }
  };

  return (
    <div className="main-page ">
      <div className="main-page__inner">
        <header className="container px-5 mx-auto flex items-center justify-between pt-[39px] mb-[200px] px-1">
          <a href="http://uzedu.uz" target="blank" className="block">
            <MainLogo />
          </a>
          <div className="flex">
            <div className="lang flex">
              <a href="/ru" className="href_lang">
                <img
                  src="https://upload.wikimedia.org/wikipedia/en/thumb/f/f3/Flag_of_Russia.svg/800px-Flag_of_Russia.svg.png"
                  alt="rus_flag"
                  className="lang_img"
                />
                Рус
              </a>
            </div>
            <a
              href="tel:+998712020909"
              className="flex items-center hover:opacity-50">
              <div className="sm:block hidden">
                <PhoneIcon />
              </div>
              <span className="ml-4 text-base font-medium text-white block">
                Call markaz <br /> (71) 202-09-09
              </span>
            </a>
          </div>
        </header>
        <div className="main-page__hero container px-5 mx-auto">
          <h1 className="uppercase text-white lg:text-7xl font-bold lg:w-[1000px] sm:mb-[36px] md:text-5xl text-3xl px-1 mb-[15px]">
            maktabgacha va maktab ta'limi vaziriga murojaat yo'llang
          </h1>
          <a
            href="#form__section"
            className="lg:text-2xl font-bold lg:py-4 lg:px-[70px] rounded-2xl bg-white text-[#4C4083] md:text-xl md:py-3 md:px-[55px] sm:text-lg sm:py-2 sm:px-[20px] text-lg py-2 px-[20px] ml-1 hover:opacity-80">
            Murojaat yo'llash
          </a>
        </div>
      </div>
      <main className="main">
        <Form setAppealID={setAppealID} appealID={appealID} />
        <br />
      </main>
      <footer className="bg-[#4C4083] py-[50px] flex justify-center items-center">
        <div className="flex ">
          <div className="footer__logo lg:mr-[130px] md:ml-[80px] sm:ml-[20px] ml-[10px] mr-[10px]">
            <MainLogo />
          </div>
          <div className="h-[200px] w-[1px] bg-white"></div>
          <div className="lg:ml-[130px] md:ml-[80px] sm:ml-[20px] ml-[10px]">
            <a
              href="tel:+998712020909"
              className="flex items-center font-light sm:text-2xl text-white mb-[34px]  sm:w-auto w-[100px] hover:opacity-80">
              <span className="footer__call sm:mr-3 mr-1 sm:block hidden">
                <PhoneIcon />
              </span>
              Call markaz (71) 202-09-09
            </a>
            <ul className="flex items-center">
              <li className="p-1">
                <a
                  href="https://www.facebook.com/uzedu/"
                  target="blank"
                  className="footer__link">
                  <FacebookIcon />
                </a>
              </li>
              <li className="p-1">
                <a
                  href="https://www.instagram.com/uzedu.uz"
                  target="blank"
                  className="footer__link">
                  <InstagramIcon />
                </a>
              </li>
              <li className="p-1">
                <a
                  href="https://t.me/uzedu/"
                  target="blank"
                  className="footer__link">
                  <TelegramIcon />
                </a>
              </li>
              <li className="p-1">
                <a
                  href="https://youtube.com/@UzEdu1"
                  target="blank"
                  className="footer__link">
                  <YoutubeIcon />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default MainPage;
