import React, { useState, useRef } from "react";
import axios from "axios";
import { Modal } from "./modal/modal";
import ReCAPTCHA from "react-google-recaptcha";
import regions from "../assets/regions.json";
import district from "../assets/district.json";

export const Form = ({ setForm, setAppealID, appealID }) => {
  const [verified, setVerified] = useState(false);
  const fileRef = useRef(null);
  const [name, setName] = useState();
  const [hudud, setHudud] = useState();
  const [tuman, setTuman] = useState();
  const [address, setAddress] = useState();
  const [email, setEmail] = useState();
  const [pass, setPass] = useState();
  const [phone, setPhone] = useState();
  const [murojat, setMurojat] = useState();
  const [alertStatus, setAlertStatus] = useState(false);
  const [matni, setMatni] = useState("");
  const [fileName, setFileName] = useState();
  const onChange = () => {
    setVerified(true);
  };
  function handleChangeFileName(e) {
    if (e.target.files[0].size <= 10485760) {
      setFileName(e?.target?.files?.[0]);
    } else {
      alert("File size should be less than 10 MB");
    }
  }

  function AddCommentHandler(r) {
    r.preventDefault();

    const formData = new FormData();
    formData.append("ism", name);
    formData.append(
      "address",
      regions.find((e) => e.id === hudud).name_ru +
        " " +
        district.find((e) => e.id === tuman).name_ru +
        " " +
        address
    );
    formData.append("hudud", hudud);
    formData.append("murojat", murojat);
    formData.append("email", email);
    formData.append("passport", pass);
    formData.append("phone", phone);
    formData.append("matni", matni);
    formData.append("file", fileRef?.current?.files?.[0]);
    const conf = {
      headers: {
        Accept: "*/*",
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post("https://bk.uzedu.uz/api/create/", formData, conf)
      .then((data) => {
        setAppealID(data.data.id);
        console.log(data);
        setAlertStatus(true);
        document.body.style.overflow = "hidden";
      })
      .catch((err) => {
        let message =
          err.response.data.errors[Object.keys(err.response.data.errors)[0]];

        alert(Object.keys(err.response.data.errors)[0] + "dagi " + message);
      });
  }

  return (
    <div
      id="form__section"
      className="container form-wrapper mx-auto mt-[66px] block bg-[#fff] sm:px-[100px] sm:py-[58px] py-[20px] px-[50px]">
      <h2 className="main__title uppercase font-400 lg:text-[48px] text-[#4C4083] text-center md:mb-[72px] md:mt-[10px] md:text-[36px]  sm:text-[36px] text-[28px] mb-[42px] mt-[20px]">
        ФОРМА ОБРАЩЕНИЯ
      </h2>
      <form
        onSubmit={AddCommentHandler}
        className="form-wrapper__form"
        encType="multipart/form-data">
        <div className="mb-[128px] xl:flex justify-between">
          <div>
            <div className="mb-[60px]">
              <label
                htmlFor="address"
                className="block mb-2 text-sm font-medium text-gray-900 ">
                Обращение <span className="text-red-500">*</span>
              </label>
              <select
                required
                onChange={(e) => setMurojat(e.target.value)}
                id="small"
                className="block w-full p-2 pl-0 mb-6 text-sm text-gray-900 border-[none] border-b border-gray-300 focus:ring-blue-500 focus:border-blue-500  border-gray-600 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500">
                <option value="" defaultValue></option>
                <option value="Maktabgachatalim">
                  Дошкольное образование{" "}
                </option>
                <option value="Maktabtalimi">Школьное образование</option>
              </select>
            </div>

            <div className="mb-[60px]">
              <label
                htmlFor="last_name"
                className="block mb-2 text-sm font-medium text-gray-900">
                Ф.И.О.<span className="text-red-500">*</span>
              </label>
              <input
                required
                onChange={(e) => setName(e.target.value)}
                type="name"
                id="last_name"
                className="xl:w-[400px] border-[none] border-b outline=[none] border-gray-300 text-gray-900 text-sm block w-full p-2.5  border-gray-600"
              />
            </div>

            <div className="mb-[60px]">
              <label
                htmlFor="small"
                className="block mb-2 text-sm font-medium text-gray-900 ">
                Область<span className="text-red-500">*</span>
              </label>
              <select
                onChange={(e) => {
                  setHudud(e.target.value);
                }}
                id="small"
                required
                className="block w-full p-2 pl-0 mb-6 text-sm text-gray-900 border-[none] border-b border-gray-300 focus:ring-blue-500 focus:border-blue-500  border-gray-600 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500">
                <option value="none" key={0} defaultValue>
                  выбрать...
                </option>
                {regions.map(({ id, name_ru }, index) => (
                  <option key={index} value={id}>
                    {name_ru}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-[60px]">
              <label
                htmlFor="small"
                className="block mb-2 text-sm font-medium text-gray-900 ">
                Район<span className="text-red-500">*</span>
              </label>
              <select
                onChange={(e) => setTuman(e.target.value)}
                id="small"
                required
                className="block w-full p-2 pl-0 mb-6 text-sm text-gray-900 border-[none] border-b border-gray-300 focus:ring-blue-500 focus:border-blue-500  border-gray-600 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500">
                <option value="" key={0} defaultValue>
                  выбрать...
                </option>
                {district.map(({ id, region_id, name_ru }, index) =>
                  region_id === parseInt(hudud) ? (
                    <option key={index} value={id}>
                      {name_ru}
                    </option>
                  ) : (
                    ""
                  )
                )}
              </select>
            </div>
            <div className="mb-[60px]">
              <label
                htmlFor="address"
                className="block mb-2 text-sm font-medium text-gray-900 ">
                Адрес<span className="text-red-500">*</span>
              </label>
              <input
                required
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                type="address"
                placeholder="Название улицы, номер дома"
                id="address"
                className="xl:w-[400px] border-[none] border-b outline=[none] border-gray-300 text-gray-900 text-sm block w-full p-2.5  border-gray-600"
              />
            </div>
          </div>
          <div className="xl:w-[400px]">
            <div className="mb-[60px]">
              <label
                htmlFor="phone"
                className="block mb-2 text-sm font-medium text-gray-900">
                Номер телефона<span className="text-red-500">*</span>
              </label>
              <input
                required
                onChange={(e) => {
                  const r = new RegExp(`[9]{2}[8][0-9]{9}`);
                  console.log();
                  if (r.test(e.target.value)) {
                    setPhone(e.target.value);
                  } else {
                    // alert("Telefon raqamida xatolik bor");
                  }
                }}
                type="tel"
                placeholder="+998123456789"
                maxLength={13}
                minLength={13}
                id="phone"
                defaultValue=""
                className="xl:w-[400px] border-[none] border-b outline=[none] border-gray-300 text-gray-900 text-sm block w-full p-2.5  border-gray-600 "
              />
            </div>
            <div className="mb-[60px]">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900">
                Электронная почта
              </label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                id="email"
                placeholder="example@gmail.com"
                className="xl:w-[400px] border-[none] border-b outline=[none] border-gray-300 text-gray-900 text-sm block w-full p-2.5  border-gray-600"
              />
            </div>
            <div className="mb-[60px]">
              <label
                htmlFor="passport"
                className="block mb-2 text-sm font-medium text-gray-900">
                Паспорт
              </label>
              <input
                onChange={(e) => setPass(e.target.value)}
                type="passport"
                id="passport"
                maxLength={9}
                minLength={9}
                placeholder="AA1234567"
                className="xl:w-[400px] border-[none] border-b outline=[none] border-gray-300 text-gray-900 text-sm block w-full p-2.5  border-gray-600"
              />
            </div>

            <div className="">
              <div className="justify-between  flex-col	">
                <label
                  htmlFor="file"
                  className="mb-4 inline-block	py-[10px] cursor-pointer px-[16px] bg-[#4C4083] text-[#fff] rounded-[6px] border hover:opacity-80">
                  Загрузить файл
                </label>
                <h4 className="subtitle mb-8 sm:mb-0">
                  Размер файла не должен превышать 10 МБ
                </h4>

                <input
                  onChange={handleChangeFileName}
                  type="file"
                  ref={fileRef}
                  multiple={true}
                  max={10}
                  className="hidden"
                  id="file"
                />
              </div>
              <span className="block mt-[10px] text-green-700">
                {fileName?.name}
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <label
            htmlFor="message"
            className="block mb-2 text-sm font-medium text-gray-900">
            Текст обращения <span className="text-red-500">*</span>
          </label>
          <span className="text-500">{matni.length}/3000</span>
        </div>
        <textarea
          onChange={(e) => setMatni(e.target.value)}
          id="message"
          rows="4"
          maxLength={3000}
          required
          className="mb-[35px] resize-none block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  border-gray-600 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"></textarea>
        <ReCAPTCHA
          sitekey="6Le28D8kAAAAAN7xZD1kPGFMhe7lkgm8mtDfAi6q"
          onChange={onChange}
        />
        <div className="md:flex justify-between">
          <div></div>
          <div className="md:flex">
            <button
              disabled={!verified}
              type="submit"
              className="px-[58px] rounded-[14px] py-[15px] text-[#fff] bg-[#4C4083] hover:opacity-80">
              Отправить
            </button>
          </div>
        </div>
      </form>
      {alertStatus && (
        <Modal
          height={90}
          onClose={() => {
            setAlertStatus(false);
            window.location.reload(true);
          }}
          title="Ваша заявка принята. "
        />
      )}
    </div>
  );
};
