import "./modal.scss";

export const Modal = ({ title, text, onClose, height }) => {
  return (
    <div className="overlay">
      <div className="modal" style={{ height: height + "px" }}>
        <div
          onClick={onClose}
          className="modal__top mb-5 flex text-xl justify-end cursor-pointer onHover cloneModal"
        >
          &times;
        </div>

        <p class="font-bold mb-2">{title}</p>
        {/* <p class="text-sm">{text}</p> */}
      </div>
    </div>
  );
};
