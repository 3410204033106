const TelegramIcon = () => {
  return (
    <svg
      width={33}
      height={30}
      viewBox="0 0 33 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.041 1.43923C30.6376 1.10367 30.1509 0.88375 29.6324 0.802872C29.114 0.721994 28.5834 0.783185 28.097 0.979934L2.70903 11.23C2.1661 11.4534 1.70393 11.8366 1.38388 12.3288C1.06384 12.821 0.901038 13.3989 0.917099 13.9858C0.933161 14.5726 1.12732 15.1407 1.47381 15.6147C1.8203 16.0886 2.30273 16.446 2.85707 16.6394L7.99249 18.4254L10.8549 27.8911C10.8938 28.0175 10.9502 28.1379 11.0226 28.2486C11.0335 28.2656 11.049 28.2783 11.0607 28.2948C11.1443 28.4114 11.2453 28.5145 11.3602 28.6004C11.3929 28.6253 11.4242 28.6487 11.4591 28.6708C11.5936 28.76 11.7426 28.8253 11.8993 28.8636L11.9161 28.8651L11.9256 28.8691C12.0199 28.8883 12.1158 28.898 12.212 28.8982C12.2213 28.8982 12.2295 28.8938 12.2386 28.8936C12.3838 28.8911 12.5277 28.8661 12.6652 28.8195C12.6972 28.8086 12.7246 28.7904 12.7556 28.7772C12.8582 28.7347 12.9553 28.6802 13.045 28.6149C13.1169 28.5544 13.1888 28.4938 13.2607 28.4333L17.0887 24.2069L22.7983 28.6298C23.3009 29.0211 23.9196 29.2339 24.5567 29.2344C25.2242 29.2335 25.8711 29.0032 26.389 28.5821C26.9068 28.1609 27.2642 27.5745 27.4011 26.9212L32.0232 4.23107C32.128 3.72039 32.0919 3.19084 31.9189 2.69907C31.7459 2.20731 31.4424 1.77183 31.041 1.43923ZM12.7737 18.8764C12.5773 19.0721 12.443 19.3214 12.3878 19.5931L11.9493 21.7237L10.8385 18.0502L16.5976 15.0512L12.7737 18.8764ZM24.5346 26.3899L17.7874 21.1632C17.5051 20.9451 17.151 20.8413 16.7957 20.8726C16.4403 20.9039 16.1098 21.0679 15.8699 21.332L14.6439 22.6852L15.0772 20.5794L25.1115 10.5452C25.351 10.306 25.4969 9.98891 25.5228 9.65141C25.5487 9.31391 25.4529 8.9783 25.2527 8.70535C25.0525 8.43241 24.7612 8.24019 24.4315 8.16351C24.1018 8.08682 23.7555 8.13076 23.4555 8.28734L9.05464 15.7852L3.77846 13.8544L29.248 3.6652L24.5346 26.3899Z"
        fill="white"
      />
    </svg>
  );
};

export default TelegramIcon;
