import MainPage from "./pages/main-page/MainPage.jsx";
import RuPage from "./pages/ru-page/RuPage.jsx";
import "./assets/main.scss";
import LoadingAnimation from "./components/LoadingAnimation";
import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);
  return (
    <div className="App">
      <LoadingAnimation loading={loading} />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/ru" element={<RuPage />} />
      </Routes>
    </div>
  );
};

export default App;
