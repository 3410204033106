const FacebookIcon = () => {
  return (
    <svg width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_440_2)">
    <path d="M21.2703 4.6478L18.6346 4.64897C16.5681 4.64897 16.168 5.63102 16.168 7.07224V10.25H21.0966L21.0948 15.2275H16.168V28H11.0272V15.2275H6.72949V10.25H11.0272V6.57961C11.0272 2.31968 13.6295 0 17.4296 0L21.2706 0.00611055L21.2703 4.6478Z" fill="white" />
  </g>
  <defs>
    <clipPath id="clip0_440_2">
      <rect width={28} height={28} fill="white" />
    </clipPath>
  </defs>
</svg>

  );
};

export default FacebookIcon;
