const YoutubeIcon = () => {
  return (
    <svg
      width={35}
      height={34}
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_122_143)">
        <path
          d="M32.4318 9.09496C32.2635 8.42262 31.9208 7.8066 31.4383 7.30912C30.9557 6.81163 30.3504 6.4503 29.6835 6.26163C27.2468 5.66663 17.5002 5.66663 17.5002 5.66663C17.5002 5.66663 7.75349 5.66663 5.31683 6.31829C4.64993 6.50697 4.04463 6.8683 3.56207 7.36578C3.07951 7.86327 2.73678 8.47929 2.56849 9.15163C2.12255 11.6245 1.90441 14.1331 1.91683 16.6458C1.90093 19.1774 2.11908 21.7051 2.56849 24.1966C2.75402 24.8481 3.10443 25.4407 3.58586 25.9171C4.0673 26.3936 4.66349 26.7379 5.31683 26.9166C7.75349 27.5683 17.5002 27.5683 17.5002 27.5683C17.5002 27.5683 27.2468 27.5683 29.6835 26.9166C30.3504 26.7279 30.9557 26.3666 31.4383 25.8691C31.9208 25.3716 32.2635 24.7556 32.4318 24.0833C32.8743 21.629 33.0925 19.1396 33.0835 16.6458C33.0994 14.1141 32.8812 11.5864 32.4318 9.09496V9.09496Z"
          stroke="white"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.3125 21.2783L22.4583 16.6458L14.3125 12.0133V21.2783Z"
          stroke="white"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_122_143">
          <rect
            width={34}
            height={34}
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default YoutubeIcon;
